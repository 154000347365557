export default [
  {
    path: '/',
    name: 'home',
    component: () => import('./../pages/Home.vue')
  },
  {
    path: '/about-project',
    name: 'aboutProject',
    component: () => import('./../pages/project-pages/AboutProject.vue')
  },
  {
    path: '/pobuca',
    name: 'pobuca',
    component: () => import('./../pages/partners/Pobuca.vue')
  },
  {
    path: '/bca',
    name: 'bca',
    component: () => import('./../pages/partners/Bca.vue')
  },
  {
    path: '/project-material',
    name: 'projectMaterial',
    component: () => import('./../pages/implementation/ProjectMaterial.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('./../pages/Contact.vue')
  }
]
