<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  page: {
    title: 'Ignite Game',
    titleTemplate: '%s | Ignite - Web Game'
  }
}
</script>

<template>
  <div id="app">
    <header>
      <Header />
    </header>
    <main class="main">
      <router-view />
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
</template>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
@import "./assets/scss/abstracts.scss";
@import "./assets/scss/main.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  color: $white;
  margin: $s-xxl 0 0 0;
}
</style>
