<script>
import BaseImage from './BaseImage.vue'
export default {
  name: 'Header',
  components: {
    BaseImage
  }
}
</script>

<template>
  <div class="header">
      <b-navbar fixed="top" class="main-nav" toggleable="lg" type="dark" variant="dark">
        <b-container>
        <b-navbar-brand to="/">
          <BaseImage src="img/logo.svg" height="60px" class="d-inline-block" alt="Ignite"/>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" class="main-nav-collapse" is-nav>
            <b-navbar-nav class="main-nav-collapse-navbar">
              <b-nav-item to="/about-project" link-classes="main-nav-link" exact-active-class="active">Περιγραφή</b-nav-item>
              <b-nav-item-dropdown class="main-nav-collapse-nav-item" text="Έταιροι">
                <b-dropdown-item to="/pobuca" exact exact-active-class="active">Pobuca</b-dropdown-item>
                <b-dropdown-item to="/bca" exact exact-active-class="active">BCA</b-dropdown-item>
              </b-nav-item-dropdown>
               <b-nav-item to="/project-material" link-classes="main-nav-link" exact-active-class="active">Υλικό</b-nav-item>
              <b-nav-item to="/contact" link-classes="main-nav-link" exact-active-class="active">Επικοινωνία</b-nav-item>
              <b-nav-item href="https://game.ignitegame.gr/" target="_blank" rel="noopener noreferrer" link-classes="main-nav-link" exact-active-class="active">Είσοδος</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
           </b-container>
      </b-navbar>
  </div>
</template>

<style scoped lang="scss">
@use './../assets/scss/abstracts' as *;

.header {
  background-color: $outer-space;

  .main-nav {
    padding: $s-s 0;
  }
}

.main-nav-link {
  font-weight: 800;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 800;

  &:hover {
    color: rgba(255, 255, 255, 0.75);
    background-color: transparent;
  }
}

.main-nav .main-nav-collapse-navbar .main-nav-collapse-nav-item,
.main-nav .nav-item {
  padding: $s-s $s-m;
}

@media only screen and (max-width: 992px) {
  .main-nav .main-nav-collapse-navbar .main-nav-collapse-nav-item,
  .main-nav .nav-item {
    padding: $s-xxs $s-m;
  }
}
</style>
