<script>
import BaseImage from './BaseImage.vue'
const currentYear = new Date().getFullYear()
export default {
  name: 'Footer',
  components: {
    BaseImage
  },
  computed: {
    currentYear () {
      return currentYear
    }
  }
}
</script>

<template>
  <div class="footer">
    <div class="project-info">
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm="4">
            <div class="project-info-title">ΔΙΑΡΚΕΙΑ</div>
            <BaseImage class="img-fluid" src="img/footer/duration.jpg" alt="Project Duration"/>
            <div class="project-info-subtitle">36 ΜΗΝΕΣ</div>
          </b-col>
          <b-col sm="4">
            <div class="project-info-title">ΚΩΔΙΚΟΣ ΕΡΓΟΥ</div>
            <BaseImage class="img-fluid" src="img/footer/project-code.png" alt="Project Code"/>
            <div class="project-info-subtitle">Τ1ΕΔΚ - 05646</div>
          </b-col>
          <b-col sm="4">
            <div class="project-info-title">ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ</div>
            <BaseImage class="img-fluid" src="img/footer/budget.png" alt="Project Budget"/>
            <div class="project-info-subtitle">345.200</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="project-financing">
      <b-container class="bv-example-row">
        <b-row>
          <b-col cols="12">
            <div class="project-financing-title">Ignite</div>
            <a href="docs/poster.pdf" download>
              <BaseImage class="img-fluid project-financing-image" src="img/footer/espa-financing.png" alt="Espa Financing"/>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="copyright">
      <p>
        Copyright © {{ currentYear }} - Pobuca | All Rights Reserved
    </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use './../assets/scss/abstracts' as *;

.footer {
  background: $black;
  color: $red;
  text-align: center;
}

.project-info {
  background-color: $white;
  padding: $s-xxl 0;
}

.project-info-title {
  font-size: $s-l;
  font-weight: 700;
  color: $red;
  margin-bottom: $s-m;
}

.project-info-image {
  width: $s-xxxl;
}

.project-info-subtitle {
  color: $red;
  font-size: $s-m;
  font-weight: 700;
  margin: $s-m 0;
}

.project-financing {
  background-color: $mine-shaft;
  padding: $s-xl 0;
}

.project-financing-title {
  font-family: "Waiting for the Sunrise", cursive;
  font-size: $s-xl;
  text-align: center;
  margin: $s-s 0 $s-l 0;
}

.project-financing-image {
  width: 700px;
}

.copyright {
  font-size: $s-m;
  margin: $s-m 0;
}
</style>
